import { ShortId } from '@property-folders/common/util/url';

type LocationInfo =
  | { type: 'default' }
  | { type: 'portal', portalType: string, portalIdRaw: string, portalId: string};

function getCurrentLocationInfo(): LocationInfo {
  const pathname = window.location.pathname;
  if (pathname.startsWith('/sign')) {
    return { type: 'default' };
  }

  const portalMatch = pathname.match(/(purchaser|vendor)\/([^/]+)\/sign/);
  if (portalMatch) {
    return { type: 'portal', portalType: portalMatch[1], portalIdRaw: portalMatch[2], portalId: ShortId.toUuid(portalMatch[2]) };
  }

  return { type: 'default' };
}

export class LinkBuilder {
  private static _locationInfo = getCurrentLocationInfo();

  static get clientSideRouterBasePath() {
    const locationInfo = this._locationInfo;
    switch (locationInfo.type) {
      case 'portal':
        // the link may be any form, normally headline + id. call this the "raw" portal id.
        // since that's what the client-side browser shows in the url bar,
        // it's what should be used as the basis for client side routing.
        return `/${locationInfo.portalType}/${locationInfo.portalIdRaw}/sign`;
      case 'default':
      default:
        return '/sign';
    }
  }

  static get restApiBasePath() {
    const locationInfo = this._locationInfo;
    switch (locationInfo.type) {
      case 'portal':
        // cookies are issued to {portalType}/{uuid}, so the portal id should be the "parsed" portal id, not the "raw"
        // one, otherwise voiding will not work.
        return `/${locationInfo.portalType}/${locationInfo.portalId}/api/rest`;
      case 'default':
      default:
        return '/api/rest';
    }
  }

  static get restApiBase(): string {
    const url: URL = import.meta.env.VITE_API_REST_URL_BASE
      ? new URL(this.restApiBasePath, import.meta.env.VITE_API_REST_URL_BASE)
      : new URL(this.restApiBasePath, window.location.origin);

    return url.toString();
  }

  static restApi(path: string) {
    return path.startsWith('/')
      ? `${this.restApiBase}${path}`
      : `${this.restApiBase}/${path}`;
  }

  static signingImages(path: string): string {
    const base: URL = import.meta.env.VITE_REAFORMS_URL_BASE
      ? new URL(import.meta.env.BASE_URL, import.meta.env.VITE_REAFORMS_URL_BASE)
      : new URL(import.meta.env.BASE_URL, window.location.origin);
    const imagesRoot = new URL('images-pfs/', base);
    return new URL(path, imagesRoot).toString();
  }
}
